/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it

import React from "react";

export const onInitialClientRender = () => {
  setTimeout(function() {
      document.getElementById("___loader").style.display = "none"
  }, 10)
}
// 네이버 애널리틱스
export const onRenderBody = ({ setHeadComponents }) => {
  setHeadComponents([
    <script
      key="naver-wcslog"
      type="text/javascript"
      src="//wcs.naver.net/wcslog.js"
    />,
    <script
      key="naver-wcslog-inline"
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `
          if (!wcs_add) var wcs_add={};
          wcs_add["wa"] = "s_4e5fa6176a3d";
          if (!_nasa) var _nasa={};
          if(window.wcs){
            wcs.inflow();
            wcs_do();
          }
        `,
      }}
    />,
  ]);
};